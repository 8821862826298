import request from '@manage/utils/request'

export function handlePageQueryUsers(data) { //分页查询用户
  return request({
    url: 'admin/bmuser/pageQuery',
    method: 'post',
    data
  })
}


export function handleViewUserDetails(id) { //查看用户详情
  return request({
    url: 'admin/bmuser/viewDetails',
    method: 'post',
    params: {
      id: id
    }
  })
}



export function handleSaveUserInfo(data) { //保存用户信息
  return request({
    url: 'admin/bmuser/saveUserInfo',
    method: 'post',
    data
  })
}




export function handleClearUserPassword(id) { //清空用户密码
  return request({
    url: 'admin/bmuser/clearUserPassword',
    method: 'post',
    params: {
      id: id
    }
  })
}



export function handleControlUserState(id) { //用户状态控制
  return request({
    url: 'admin/bmuser/controlUserState',
    method: 'post',
    params: {
      id: id
    }
  })
}


export function handleInitUserPassword(id) { //初始化用户密码
	return request({
		url: 'admin/bmuser/initUserPassword',
		method: 'post',
		params:{id:id}
	})
}


export function handleUserLogoff(data) { //用户注销
  return request({
    url: 'admin/bmuser/userLogoff',
    method: 'post',
    data
  })
}
